import Rest from '@/services/Rest';

/**
 * @typedef {CurrencyCurrenciesService}
 */
export default class CurrencyCurrenciesService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/currency/currencies'
}
