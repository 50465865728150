<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-sales"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      data-anima="sidebar"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel text-left">
          <b-col>
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <b-row class="mb-3">
                    <b-col cols="5">
                      <p class="label-campo">{{ $t('shared.text_1996') }}</p>
                      <p class="dados-campo">{{ cupom.name }}</p>
                    </b-col>
                    <b-col cols="6">
                      <p class="label-campo">{{ $t('shared.text_1997') }}</p>
                      <template>
                        <p
                          class="dados-campo"
                          :class="getStatus(cupom.status).class"
                        >
                          {{ getStatus(cupom.status).text }}
                        </p>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="5">
                      <p class="label-campo">{{ $t('shared.text_1998') }}</p>
                      <p class="dados-campo">
                        {{ cupom.created_at | datetime }}
                      </p>
                    </b-col>
                    <b-col cols="6">
                      <p class="label-campo">{{ $t('shared.text_2425') }}</p>
                      <p class="dados-campo">
                        {{
                          cupom.type == "PERCENTAGE"
                            ? `${cupom.amount}%`
                            : `R$ ${formatMoney(cupom.amount)}`
                        }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col cols="5">
                      <p class="label-campo">Moeda</p>
                      <p class="dados-campo">
                        {{ cupom.currency ? cupom.currency.currency : 'BRL' }}
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="sumary detalhes">
                  <h6>{{ $t('shared.text_1999') }}</h6>
                  <b-row class="mb-3">
                    <b-col cols="12">
                      <p class="label-campo">{{ $t('shared.text_2000') }}</p>
                      <p class="dados-campo" v-if="!cupom.only_products">
                        {{ $t('shared.text_2001') }}
                      </p>
                      <p class="dados-campo" v-if="cupom.only_products">
                        <span
                          v-for="product in cupom.products"
                          :key="product.product_id"
                          class="produto-item"
                        >
                          • {{ product.product.name }}
                        </span>
                      </p>
                    </b-col>
                    <b-col cols="6" class="mt-3" v-if="cupom.due_date">
                      <p class="label-campo">{{ $t('shared.text_2002') }}</p>
                      <p class="dados-campo">
                        {{ $t('shared.text_2003') }} {{ cupom.due_date | datetime }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <p class="label-campo">{{ $t('shared.text_2004') }}</p>
                      <p class="dados-campo">
                        {{
                          cupom.limit ? `${cupom.limit} ${$t('shared.text_2426')}` : $t('shared.text_2005')
                        }}
                      </p>
                    </b-col>
                    <b-col cols="6">
                      <p class="label-campo">{{ $t('shared.text_2427') }}</p>
                      <p class="dados-campo">
                        {{ cupom.used }}
                        {{ cupom.limit ? `de ${cupom.limit}` : "" }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col cols="6">
                      <p class="label-campo">Permite a conversão</p>
                      <p class="dados-campo">
                        {{
                          cupom.convert_amount === 1 || cupom.convert_amount ? "Sim" : "Não"
                        }}
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="sumary detalhes acoes mt-4">
                  <h6>{{ $t('shared.text_2006') }}</h6>
                  <b-row>
                    <BaseButton variant="info3" v-if="!$store.getters.setPanel || ($store.getters.setPanel && cupomEdit)" @click="editCupom"
                      >{{ $t('shared.text_2007') }}</BaseButton
                    >
                    <BaseButton
                      variant="info2"
                      class="mt-3"
                      v-if="
                        (cupom.status == 'active' || cupom.status == 'expired') && (!$store.getters.setPanel || ($store.getters.setPanel && cupomEdit))
                      "
                      @click="updateStatus(cupom.id, cupom.name, false)"
                      >{{ $t('shared.text_2428') }}</BaseButton
                    >
                    <BaseButton
                      variant="info2"
                      class="mt-3"
                      v-if="cupom.status == 'disabled'  && (!$store.getters.setPanel || ($store.getters.setPanel && cupomEdit))"
                      @click="updateStatus(cupom.id, cupom.name, true)"
                      >{{ $t('shared.text_2008') }}</BaseButton
                    >
                    <div id="btn-excluir" class="w-100">
                      <BaseButton
                        variant="danger"
                        class="mt-3"
                        :disabled="cupom.used > 0"
                        v-if="!$store.getters.setPanel || ($store.getters.setPanel && cupomDelete)"
                        @click="
                          cupom.used > 0
                            ? ''
                            : removeCupom(cupom.id, cupom.name)
                        "
                        >{{ $t('shared.text_2009') }}</BaseButton
                      >
                    </div>
                    <b-tooltip
                      v-if="cupom.used > 0"
                      target="btn-excluir"
                      :title="$t('shared.text_2010')"
                      placement="topleft"
                    />
                  </b-row>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
    <!-- <NewCoupon :cupom=this.$t('shared.text_2429') /> -->
  </div>
</template>
<script>
import Vue from "vue";

import Money from "@/mixins/money";
import CouponService from "@/services/resources/CouponService";
const serviceCoupon = CouponService.build();

export default {
  name: "DetalhesCupom",
  props:['cupomEdit', 'cupomDelete'],
  mixins: [Money],
  data() {
    return {
      loading: true,
      visible: false,
      cupom_id: -1,
      cupom: {},
    };
  },
  computed: {
    userID() {
      return this.$store.getters.getProfile.id;
    },
  },
  methods: {
    atualizaCupom() {
      this.$emit("close");
    },
    editCupom() {
      this.$emit("edit", this.cupom);
      this.visible = false;
    },
    updateStatus(id, name, active) {
      Vue.swal({
        title: `${active ? this.$t('shared.text_2133') : this.$t('shared.text_2134')} ${this.$t('shared.text_2429')}`,
        text: `Deseja ${active ? this.$t('shared.text_2133') : this.$t('shared.text_2134')} ${this.$t('shared.text_2135')} ${name} ?`,
        type: "danger",
        showCancelButton: true,
          confirmButtonText: `${active ? this.$t('shared.text_2133') : this.$t('shared.text_2134')} ${this.$t('shared.text_2136')}`,
        cancelButtonText: this.$t('shared.text_1984'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-info2 mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let data = {
            id: id,
            is_active: active,
          };

          serviceCoupon
            .update(data)
            .then((response) => {
              if (response) {
                this.cupom.status = active ? "active" : "disabled";
                this.$bvToast.toast(
                  `${this.$t('shared.text_2429')} ${name} ${active ? this.$t('shared.text_2012') : this.$t('shared.text_2013')}`,
                  {
                    title: this.$t('shared.text_2429'),
                    variant: "info",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                this.$emit("close");
              } else {
                this.loading = false;

                this.$bvToast.toast(
                  `${this.$t('shared.text_2011')} ${name}`,
                  {
                    title: this.$t('shared.text_2429'),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    removeCupom(id, name) {
      Vue.swal({
        title: this.$t('shared.text_2009'),
        text: `${this.$t('shared.text_2015')} ${name} ?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t('shared.text_2014'),
        cancelButtonText: this.$t('shared.text_1984'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let data = {
            id: id,
          };
          serviceCoupon
            .destroy(data)
            .then((response) => {
              if (response) {
                this.$bvToast.toast(`${this.$t('shared.text_2429')} ${name} ${this.$t('shared.text_2016')}`, {
                  title: this.$t('shared.text_2429'),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$emit("close");
                this.visible = false;
              } else {
                this.loading = false;

                this.$bvToast.toast(
                  `${this.$t('shared.text_2430')} ${name}`,
                  {
                    title: this.$t('shared.text_2429'),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "active": {
            r = {
              text: this.$t('shared.text_2017'),
              class: "icon-green",
            };
            break;
          }
          case "disabled": {
            r = { text: this.$t('shared.text_2018'), class: "icon-blue" };
            break;
          }
          case "deleted": {
            r = { text: this.$t('shared.text_2019'), class: "icon-red" };
            break;
          }

          case "expired": {
            r = { text: this.$t('shared.text_2020'), class: "icon-warning" };
            break;
          }

          default: {
            r = { text: status, class: "icon-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "icon-black" };
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    },
    openPanel(cupom_id) {
      this.visible = true;
      this.cupom_id = cupom_id;
      this.fetchCoupon();
    },

    fetchCoupon() {
      this.loading = true;

      let data = {
        id: this.cupom_id,
      };

      serviceCoupon
        .read(data)
        .then((response) => {
          this.cupom = response;
          if (!this.cupom.name) {
            this.visible = false;
            this.$bvToast.toast(this.$t('shared.text_2021'), {
              title: this.$t('shared.text_2429'),
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  .sumary {
    font-size: 13px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
  .product-name span {
    display: block;
    margin: 10px 0 !important;
  }

  .product-image {
    height: 170px;
    overflow: hidden;
    border-radius: 5px;
    background: #f5f2fc;
    display: flex;
    align-items: center;
  }
  .thumb-fake {
    width: 100%;
    color: silver;
    font-size: 32px;
    letter-spacing: 2px;
    justify-content: center;
  }

  .product-description {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: #81858e;
    font-weight: normal;
  }
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.detalhe-item {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.detalhe-item + .detalhe-item {
  margin-top: 25px;
}
.detalhe-item:first-child img {
  margin-right: 23px;
}
.detalhe-item:nth-child(2) img {
  margin-right: 28px;
}
.detalhe-item:nth-child(3) img {
  margin-right: 20px;
}
header.b-sidebar-header {
  padding: 30px !important;
  padding-bottom: 0 !important;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $base-color;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.label-campo span {
  font-weight: normal;
  margin-left: 5px;
}
.label-campo + .label-campo {
  margin-top: 5px;
}
.btn-flex {
  display: flex;
  flex-direction: column;
}
.btn-flex div {
  margin-bottom: 13px;
}
.btn-flex div button,
.btn-flex button {
  width: 100%;
  height: 55px;
}
.whats-cliente {
  font-size: 14px;
  color: #009488;
  margin-left: 5px;
}
.whats-cliente svg {
  margin-right: 5px;
}
// metas

.meta-item + .meta-item {
  margin-top: 10px;
  border-top: 1px solid #ededf0;
  padding: 10px 0;
}

.meta-item .key-meta {
  font-weight: 600;
  margin-bottom: 5px;
}
.meta-item .key-value {
  font-weight: 500;
  margin-left: 10px;
}
.sem-historico {
  margin-top: 20px;
  font-size: 14px;
  color: #81858e;
}

.decoration-none {
  text-decoration: none;
}

.btn-link-contract {
  display: flex;
  align-items: center;
  color: #2133d2;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px !important;
  border-radius: 5px;
  height: 45px;
}

.btn-link-contract img {
  margin-right: 10px;
}
.product-avaliacao {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 7px;
  font-size: 14px;
}

.product-avaliacao + .product-avaliacao {
  margin-top: 25px;
}
.product-avaliacao.text-avaliacao {
  font-weight: normal;
  align-items: flex-start;
}

.detalhes {
  background: #f3f3f3;
  padding: 25px;
  border-radius: 5px;
}
.detalhes h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
}
.acoes {
  background: transparent;
  border: 1px solid #e5e5e5;
}
.acoes button {
  width: 100%;
}
.produto-item {
  display: block;
}
</style>