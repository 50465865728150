<template>
  <BaseModal
    name="coupon-new"
    size="lg"
    :title="$t('seller.coupon.new.text_253')"
    @hidden="reset"
    @shown="openModal"
  >
    <b-row>
      <b-col cols="12">
        <!-- Loading -->
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-form novalidate v-show="!loading">
          <b-row>
            <b-col class="p-2" cols="12" md="5">
              <b-form-group :label="$t('seller.coupon.new.text_254')" label-for="name">
                <b-form-input
                  style="text-transform: uppercase"
                  id="name"
                  name="name"
                  v-model="cupom.name"
                  type="text"
                  placeholder="Ex: GREENN50"
                  v-validate="{ required: true, regex: /^[A-Z\-_0-9]{5,15}$/i }"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  {{ $t('seller.coupon.new.text_255') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <!-- <b-col class="p-2" cols="12" md="5" v-if="isHeaven">
              <b-form-group label="Moeda do cupom" label-for="name">
                <SelectCurrency
                  :currency="currency"
                  @update-coin="updateCoin"
                />
              </b-form-group>
            </b-col> -->
          </b-row>

          <b-row>
            <b-col class="p-2" cols="12" md="6">
              <b-form-group :label="$t('seller.coupon.new.text_1813')" label-for="type">
                <b-form-select
                  name="type"
                  v-model="cupom.type"
                  :options="types"
                  v-validate="'required'"
                  :class="{'heaven': isHeaven}"
                ></b-form-select>
                <b-form-invalid-feedback :state="!errors.has('type')">
                  {{ $t('seller.coupon.new.text_1814') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col class="p-2" cols="12" md="4">
              <b-form-group
                v-if="cupom.type == 'PERCENTAGE'"
                :label="$t('seller.coupon.new.text_256')"
                label-for="amount"
              >
                <b-form-input
                  id="amount"
                  name="amount"
                  v-model="cupom.amount"
                  type="number"
                  min="1"
                  max="100"
                  v-validate="'required|decimal|min_value:1|max_value:100'"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>

                <b-form-invalid-feedback :state="!errors.has('amount')">
                  {{ $t('seller.coupon.new.text_257') }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                v-if="cupom.type != 'PERCENTAGE'"
                :label="`${$t('seller.coupon.new.text_1815')} ${currency.currency_symbol}`"
                label-for="amount"
              >
                <money
                  id="amount"
                  name="amount"
                  v-model="cupom.amount"
                  v-bind="money"
                  :class="{'heaven': isHeaven}"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col v-if="!$store.getters.setPanel || ($store.getters.setPanel && product_read)"
              class="p-2" cols="12" md="6"
            >
              <b-form-group
                id="only_products"
                label=""
                label-for="only_products"
              >
                <b-form-checkbox
                  name="only_products"
                  v-model="cupom.only_products"
                  size="lg"
                  switch
                >
                  <p class="info-checkbox">
                    {{ $t('seller.coupon.new.text_258') }}
                    <span class="opcional">(opcional)</span>
                    <span class="descricao-checkbox"
                      >{{ $t('seller.coupon.new.text_259') }}
                    </span>
                  </p>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col v-if="!$store.getters.setPanel || ($store.getters.setPanel && product_read)"
              class="p-2" cols="12" md="6"
            >
              <b-form-group
                v-if="isHeaven"
                id="convert_amount"
                label=""
                label-for="convert_amount"
              >
                <b-form-checkbox
                  name="convert_amount"
                  v-model="cupom.convert_amount"
                  size="lg"
                  switch
                >
                  <p class="info-checkbox">
                    {{ $t('seller.coupon.new.text_273') }}
                    <span class="opcional">{{ $t('seller.coupon.new.text_274') }}</span>
                    <span class="descricao-checkbox">
                      {{ $t('seller.coupon.new.text_275') }}
                    </span>
                  </p>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col v-if="cupom.only_products" cols="12" class="mb-2 p-2">
              <b-form-group :label="$t('seller.coupon.new.text_260')">
                <multiselect
                  v-model="cupom.products"
                  id="products_cupom"
                  name="products_cupom"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.coupon.new.text_1816')"
                  :selectLabel="$t('seller.coupon.new.text_1817')"
                  :deselectLabel="$t('seller.coupon.new.text_1818')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading_product"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  v-validate="'required'"
                  :class="{'heaven': isHeaven}"
                  @search-change="aux_product_debounce"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t('seller.coupon.new.text_1819') }}</span>
                  </template> 
                  <template v-slot:noResult>
                    <span>{{ $t('seller.coupon.new.text_261') }}</span>
                  </template> 
                </multiselect>
                <b-form-invalid-feedback :state="!errors.has('products_cupom')">
                  {{ $t('seller.coupon.new.text_1820') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col class="p-2" cols="12" md="6">
              <b-row>
                <b-col>
                  <b-form-group id="limit" label="" label-for="limit">
                    <b-form-checkbox
                      name="limit"
                      v-model="has_limit"
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        {{ $t('seller.coupon.new.text_263') }} <span class="opcional">(opcional)</span>

                        <span class="descricao-checkbox"
                          >{{ $t('seller.coupon.new.text_262') }}
                        </span>
                      </p>
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group
                    :label="$t('seller.coupon.new.text_1821')"
                    label-for="qtd"
                    data-anima="top"
                    v-if="has_limit"
                  >
                    <b-form-input
                      id="qtd"
                      name="qtd"
                      v-model="cupom.limit"
                      min="1"
                      type="number"
                      v-validate="'required|numeric|min_value:1'"
                      :class="{'heaven': isHeaven}"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('qtd')">
                      {{ $t('seller.coupon.new.text_264') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col class="p-2" cols="12" md="6">
              <b-row>
                <b-col>
                  <b-form-group id="validate" label="" label-for="validate">
                    <b-form-checkbox
                      name="validate"
                      v-model="has_validate"
                      size="lg"
                      @change="changeHasValidate"
                      switch
                    >
                      <p class="info-checkbox">
                        {{ $t('seller.coupon.new.text_266') }} <span class="opcional">(opcional)</span>

                        <span class="descricao-checkbox"
                          >{{ $t('seller.coupon.new.text_265') }}
                        </span>
                      </p>
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group
                    id="validate"
                    label=""
                    label-for="validate"
                    v-if="has_validate"
                  >
                    <b-form-group
                      :label="$t('seller.coupon.new.text_1822')"
                      label-for="qtd"
                      data-anima="top"
                    >
                      <b-form-input
                        id="date"
                        name="date"
                        v-model="cupom.due_date"
                        type="datetime-local"
                        :min="minTime"
                        v-validate="'required'"
                        :class="{'heaven': isHeaven}"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!errors.has('date')">
                        {{ $t('seller.coupon.new.text_267') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="link-info"
        :disabled="loading"
        @click="cancel"
        class="mr-4"
      >
        {{ $t('seller.coupon.new.text_1823') }}
      </BaseButton>
      <BaseButton variant="black" :disabled="loading" @click="onSubmit">
        {{ cupom_edit.name ? $t('seller.coupon.new.text_268') : $t('seller.coupon.new.text_269') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
import { Money } from 'v-money'
import moment from 'moment-timezone'
import SelectCurrency from '@/components/SelectCurrency.vue'
import gtagSend from '@/utils/gtagSend';

import ProductService from '@/services/resources/ProductService'
import CouponService from '@/services/resources/CouponService'

const serviceProduct = ProductService.build()
const serviceCoupon = CouponService.build()

export default {
  name: 'NewCupom',
  components: {
    Multiselect,
    Money,
    SelectCurrency,
  },
  props: {
    product_read: {
      type: Boolean,
      default: false,
    },
    cupom_edit: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      submit: false,
      loading: false,
      loading_product: false,
      products: [],
      search: "",
      currency: {
        id: 1,
        name: "Real",
        currency: "BRL",
        currency_symbol: "R$"
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      has_limit: false,
      has_validate: false,
      minTime: null,
      cupom: {
        name: "",
        type: "PERCENTAGE",
        amount: 0,
        only_products: false,
        products: [],
        convert_amount: false,
        limit: null,
        due_date: null,
      },
      products_selects: [],
      types: [
        { value: "PERCENTAGE", text: this.$t('seller.coupon.new.text_1824') },
        { value: "AMOUNT", text: this.$t('seller.coupon.new.text_1825') }
      ],
      coins: [
        {
          id: 1,
          name: "Real",
          currency: "BRL",
          currency_symbol: "R$"
        },
        {
          id: 2,
          name: "Dolar",
          currency: "USD",
          currency_symbol: "$"
        },
        {
          id: 3,
          name: "Euro",
          currency: "EUR",
          currency_symbol: "€",
        },
        {
          id: 4,
          name: "Libra",
          currency: "GBP",
          currency_symbol: "₤",
        }
      ]
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    changeHasValidate(e) {
      if (!this.cupom_edit.name) {
        this.cupom.due_date = e ? moment().add(5, "minutes").format() : null;

        this.minTime = e ? moment().add(5, "minutes").format() : this.minTime;
      }
    },
    updateCoin(coin) {
      this.currency = coin;
      this.money.prefix = coin.currency_symbol + " "
    },
    openModal() {
      if (this.cupom_edit.name) {
        this.cupom = Object.assign({}, this.cupom_edit)

        if (this.cupom.convert_amount === 1 || this.cupom.convert_amount) {
          this.cupom.convert_amount = true
        } else {
          this.cupom.convert_amount = false
        }
        
        let myArray = this.coins;
        myArray.filter(x => x.id === this.cupom.currency_id)
        this.currency = myArray[0]

        this.cupom.only_products = this.cupom.only_products ? true : false
        if (this.cupom.limit) {
          this.has_limit = true
        }
        if (this.cupom.due_date) {
          this.cupom.due_date = moment(this.cupom.due_date).format(
            "YYYY-MM-DD[T]HH:mm"
          );
          this.has_validate = true
        } else {
          this.cupom.due_date = moment()
            .add(5, "minutes")
            .format("YYYY-MM-DD[T]HH:mm")

          this.minTime = this.cupom.due_date
        }
        this.cupom.products = this.cupom.products.map((item) => {
          return {
            id: item.product.id,
            name: item.product.name
          }
        })
      } else {
        this.cupom.due_date = moment().add(5, "minutes").format()
        this.minTime = this.cupom.due_date
      }
    },
    reset() {
      this.has_limit = false;
      this.has_validate = false;
      this.minTime = null;
      this.cupom = {
        name: "",
        type: "PERCENTAGE",
        amount: 0,
        only_products: false,
        products: [],
        limit: null,
        due_date: null,
      };

      this.products_selects = [];

      if (this.cupom_edit.name) {
        this.$emit("resetEdit");
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading_product = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function () {
      this.fetchProducts(this.search);
    }, 500),

    fetchProducts(search = null) {
      let data = { list: true}
      if (search) data.search = search
      this.products = []

      serviceProduct
        .search(data)
        .then(response => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name })
          })
        })
        .catch(err => {
          console.error(err)
          this.products = []
        })
        .finally(() => { this.loading_product = false })
    },
    formatData() {
      return new Promise((resolve) => {
        this.cupom.name = this.cupom.name.toUpperCase();
        this.cupom.amount = +this.cupom.amount;
        this.cupom.limit =
          this.cupom.limit && this.has_limit ? +this.cupom.limit : null;
        if (this.has_validate) {
          if (new Date(this.cupom.due_date).getTime() < moment.now()) {
            this.$bvToast.toast(
              `${this.$t('seller.coupon.new.text_270')} ${moment()
                .add(5, "minutes")
                .format("DD/MM/YYYY HH:mm")}`,
              {
                title: this.$t('seller.coupon.new.text_1826'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            resolve(false);
          }
          this.cupom.due_date = moment
            .tz(this.cupom.due_date, "America/Sao_Paulo")
            .utc()
            .format();
        } else {
          this.cupom.due_date = null;
        }

        if (this.cupom.only_products && this.cupom.products.length) {
          this.products_selects = [...this.cupom.products];
          this.cupom.products = this.cupom.products.map((item) => item.id);
        } else {
          this.cupom.products = [];
        }
        resolve(true);
      });
    },
    onSubmit() {
      this.submit = true;

      if(this.cupom.type != 'PERCENTAGE'){
        if(this.cupom.amount === 0){
          this.$bvToast.toast("Valor do cupom invalido!", {
            title: "Cupom",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return
        }
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.formatData().then((format) => {
            if (format) {
              this.loading = true;

              if (!this.cupom_edit.name) {
                this.createCupom();
              } else {
                this.updateCupom();
              }
            }
          });
        }
      });
    },
    createCupom() {
      let data = this.cupom;
      data.currency_id = this.currency.id;

      if (data.convert_amount) {
        data.convert_amount = true;
      } else {
        data.convert_amount = false;
      }
      console.log('data', data);
     
      serviceCoupon
        .create(data)
        .then(() => {
          const productIds = data.only_products && data.products && data.products.length > 0
            ? data.products.join(',')
            : '';
          gtagSend('cupom_desconto_criado',{
            ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
            codigo_do_cupom: data.name,
            tipo_de_desconto: data.type,
            valor_do_desconto: data.amount,
            aplicavel_a_produtos_especificos: data.only_products ? "Sim" : "Não",
            produtos_ids: productIds,
            limite_de_uso: data.limit? "Sim" : "Não",
            validade: data.due_date ? "Sim" : "Não",
            data_de_inicio: moment().format('DD/MM/YYYY HH:mm:ss'),
            data_de_fim: data.due_date ? moment(data.due_date).format('DD/MM/YYYY HH:mm:ss') : ""
          })
          this.$bvToast.toast(this.$t('seller.coupon.new.text_271'), {
            title: this.$t('seller.coupon.new.text_1826'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });

          this.$emit("close");
          this.$bvModal.hide("coupon-new");
        })
        .catch((err) => {
          console.log(err);
          if (this.products_selects.length) {
            this.cupom.products = this.products_selects;
          }
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    updateCupom() {
      let data = this.cupom;
      data.currency_id = this.currency.id;

      if (data.convert_amount) {
        data.convert_amount = true;
      } else {
        data.convert_amount = false;
      }

      serviceCoupon
        .update(data)
        .then(() => {
          this.$bvToast.toast(this.$t('seller.coupon.new.text_272'), {
            title: this.$t('seller.coupon.new.text_1826'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });

          this.$emit("close");
          this.$bvModal.hide("coupon-new");
        })
        .catch((err) => {
          console.log(err);
          if (this.products_selects.length) {
            this.cupom.products = this.products_selects;
          }
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped lang="scss">
.produto-nome {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}

.label-title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}

.title-checkbox {
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: #333333;
  font-weight: normal;
}
.info-checkbox {
  margin-top: 0;
  cursor: pointer;
  font-weight: 600;
}
.info-checkbox span {
  margin-left: 0;
  font-weight: normal;
}
.v-money {
  width: 100%;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>
