<template>
  <BaseModal
    name="prelink-cupom"
    size="md"
    :title="$t('seller.coupon.pre_link.text_273')"
    @hidden="reset"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-prelink" v-else>
      <p>
        {{ $t('seller.coupon.pre_link.text_274') }}
      </p>
      <b-form novalidate class="mt-3">
        <b-form-group :label="$t('seller.coupon.pre_link.text_2482')" label-for="product_id">
          <multiselect
            id="ajax"
            label="name"
            track-by="id"
            v-model="data.produto"
            :placeholder="$t('seller.coupon.pre_link.text_1827')"
            :selectLabel="$t('seller.coupon.pre_link.text_1828')"
            :deselectLabel="$t('seller.coupon.pre_link.text_1829')"
            selectedLabel="✔️"
            open-direction="bottom"
            :options="products"
            :multiple="false"
            :searchable="true"
            :loading="loading_product"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="10"
            :show-no-results="true"
            :hide-selected="true"
            :class="{'heaven': isHeaven}"
            @search-change="aux_product_debounce"
          >
            <template v-slot:noOptions>
              <span>{{ $t('seller.coupon.pre_link.text_1830') }}</span>
            </template> 
            <template v-slot:noResult>
              <span>{{ $t('seller.coupon.pre_link.text_1831') }}</span>
            </template> 
          </multiselect>
          <b-form-invalid-feedback :state="!errors.has('product_id')">
            {{ $t('seller.coupon.pre_link.text_1832') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- cupom -->
        <b-form-group
          :label="$t('seller.coupon.pre_link.text_275')"
          label-for="cupom_id"
          v-if="data.produto"
        >
          <multiselect
            data-anima="top"
            v-model="data.cupom"
            :placeholder="$t('seller.coupon.pre_link.text_1833')"
            label="name"
            track-by="id"
            :options="cupons"
            :multiple="false"
            :taggable="false"
            :class="{'heaven': isHeaven}"
          >
            <template v-slot:noOptions>
              <span>{{ $t('seller.coupon.pre_link.text_276') }}</span>
            </template> 

            <template v-slot:noResult>
              <span>{{ $t('seller.coupon.pre_link.text_277') }}</span>
            </template> 
          </multiselect>
          <b-form-invalid-feedback :state="!errors.has('cupom_id')">
            {{ $t('seller.coupon.pre_link.text_278') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </section>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="link-info"
        :disabled="loading"
        @click="cancel"
        class="mr-4"
      >
        {{ $t('seller.coupon.pre_link.text_1834') }}
      </BaseButton>

      <div id="redirect-link">
        <BaseButton
          variant="black"
          :disabled="loading || !data.produto || !data.cupom"
          @click="redirectLink"
        >
          {{ $t('seller.coupon.pre_link.text_1835') }}
        </BaseButton>
      </div>

      <b-tooltip
        v-if="!data.produto || !data.cupom"
        target="redirect-link"
        :title="$t('seller.coupon.pre_link.text_2483')"
      />
    </template>
  </BaseModal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import _ from 'lodash'

import ProductService from '@/services/resources/ProductService'
import CouponService from '@/services/resources/CouponService'

const serviceProduct = ProductService.build()
const serviceCoupon = CouponService.build()

export default {
  name: 'PreLink',
  components: { Multiselect },
  data() {
    return {
      loading: false,
      loading_product: false,
      products: [],
      cupons: [],
      data: {
        produto: null,
        cupom: null,
      },
    };
  },
  watch: {
    "data.produto": function (val) {
      if (val) {
        this.getCupom(val.id);
      }
    },
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    reset() {
      this.products = [];
      this.cupons = [];
      this.data = {
        produto: null,
        cupom: null,
      };
    },
    redirectLink() {
      const produto = this.data.produto;
      const cupom = this.data.cupom;
      this.$bvModal.hide("prelink-cupom");
      this.$emit("emitLink", {"cupom":cupom, "produto":produto});
    },
    // produto
    aux_product_debounce(query) {
      this.loading_product = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function () {
      this.fetchProducts(this.search);
    }, 500),

    fetchProducts(search = null) {
      let data = {
        list: true,
      };

      if (search) data.search = search;

      this.products = [];

      serviceProduct
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            this.products.push({
              id: element.id,
              name: element.name,
              type: element.type,
            });
          });
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading_product = false;
        });
    },
    getCupom(product_id) {
      this.loading = true;

      let data = {
        id: `product/${product_id}`,
      };

      this.cupons = [];

      serviceCoupon
        .read(data)
        .then((response) => {
          response.forEach((el) => {
            this.cupons.push({
              id: el.id,
              name: el.name,
            });
          });
        })
        .catch((err) => console.error(err))
        .finally(() => { this.loading = false; })
    }
  }
}
</script>

<style scoped lang="scss">
.container-prelink p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>