<template>
  <div class="container">
    <BaseHeader :title="$t('views.cupom.text_979')">
      <div class="d-md-flex d-block">
        <div>
          <BaseButton
            variant="link-info"
            class="mt-4 mt-md-0 mr-md-3"
            v-if="
              !$store.getters.setPanel ||
              ($store.getters.setPanel && productRead && linkRead)
            "
            @click="openModal('prelink-cupom')"
            >{{ $t('views.cupom.text_980') }}</BaseButton
          >
        </div>

        <div>
          <BaseButton
            variant="info2"
            class="mt-3 mt-md-0"
            v-if="
              !$store.getters.setPanel || ($store.getters.setPanel && cupomMake)
            "
            @click="openModal('coupon-new')"
            >{{ $t('views.cupom.text_981') }}</BaseButton
          >
        </div>
      </div>
    </BaseHeader>

    <div class="container-table">
      <p class="qtd-cupom">{{ coupons.length }} {{ $t('views.cupom.text_982') }}</p>
      <!-- HEADER -->
      <b-row class="Table-header mb-1 mt-5">
        <b-col cols="5" md="2"> {{ $t('views.cupom.text_2205') }} </b-col>

        <b-col cols="2" class="d-none d-md-block"> {{ $t('views.cupom.text_2206') }} </b-col>

        <b-col cols="5" md="2"> {{ $t('views.cupom.text_2207') }} </b-col>

        <b-col cols="5" md="1" v-if="isHeaven"> Moeda </b-col>

        <b-col cols="2" class="d-none d-md-block"> {{ $t('views.cupom.text_2208') }} </b-col>

        <b-col cols="2" class="d-none d-md-block"> {{ $t('views.cupom.text_2209') }} </b-col>

        <b-col cols="2" md="1"> {{ $t('views.cupom.text_2210') }} </b-col>
      </b-row>
      <!-- END HEADER -->

      <!-- BODY -->
      <template v-if="!loading && coupons.length">
        <b-row
          class="Table-body"
          v-for="item in coupons"
          :key="item.id"
          :class="getStatus(item.status).class"
        >
          <!-- Coupon Name -->
          <b-col
            cols="5"
            md="2"
            id="id_cupom"
            v-b-tooltip="{
              title: getStatus(item.status).text,
              placement: 'left',
            }"
          >
            {{ item.name }}
          </b-col>
          <!-- Creation Date -->
          <b-col cols="2" class="d-none d-md-block">
            {{ item.created_at | datetime }}
          </b-col>
          <!-- Discount -->
          <b-col cols="5" md="2" class="">
            {{
              item.type == "PERCENTAGE"
                ? `${item.amount}%`
                : `${item.currency ? item.currency.currency_symbol : 'R$'} ${formatMoney(item.amount)}`
            }}
          </b-col>
          <!-- Currency -->
          <b-col cols="1" class="d-none d-md-block" v-if="isHeaven">
            {{ item.currency ? item.currency.currency : 'BRL' }}
          </b-col>
          <!-- Validity -->
          <b-col v-if="item.due_date" cols="2" class="d-none d-md-block">
            {{ item.due_date | datetime }}
          </b-col>
          <b-col v-else cols="2" class="d-none d-md-block">
            Sem validade
          </b-col>
          <!-- Use / Limit -->
          <b-col cols="2" class="d-none d-md-block">
            {{ item.used }} / {{ item.limit || $t('views.cupom.text_2211') }}
          </b-col>
          <!-- Action -->
          <b-col cols="2" md="1">
            <div class="Table-body-action" @click="openPanel(item.id)">
              <img src="@/assets/img/icons/eye.svg" />
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <Paginate
            v-if="coupons.length"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            :items-per-page="pagination.perPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <div
        v-if="loading && !coupons.length"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !coupons.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t('views.cupom.text_983') }}</p>
      </b-row>


      <!-- modals -->
      <CouponPreview
        ref="couponPreview"
        @close="fetchCoupons"
        @edit="editCupom"
        :cupomEdit="cupomEdit"
        :cupomDelete="cupomDelete"
      />
      <NovoLink
        :checkouts="checkouts"
        :product_id="product.id"
        :product_type="product.type"
        :product_name="product.name"
        :product_installments="validItem(product.max_boleto_installments)"
        :productsS="productsSeller"
        :productsA="productsAffiliate"
        :type="false"
        :_cupom="cupom"
        :cupom_name="cupom_name"
        :has_orderbump="null"
        :all_edit="null"
        :linkCreate="linkCreate"
        :linkEdit="linkEdit"
        :linkDelete="linkDelete"
        :checkoutRead="checkoutRead"
      />
      <NewCoupon
        @close="fetchCoupons"
        :product_read="productRead"
        :cupom_edit="cupom_edit"
        @resetEdit="cupom_edit = {}"
      />
      <Prelink @emitLink="makeLink" />
    </div>
    <CallEvaluation />
  </div>
</template>
<script>
import Paginate from "@/components/shared/Paginate";
import Money from "@/mixins/money";
import CouponPreview from "@/components/shared/CouponPreview";
import NewCoupon from "@/components/Seller/Coupon/new";
import Prelink from "@/components/Seller/Coupon/PreLink";
import NovoLink from "@/components/Seller/Links/modals/new";
import Cookies from "js-cookie";

import ProductService from '@/services/resources/ProductService';
import CouponService from "@/services/resources/CouponService";
import AffiliateService from '@/services/resources/AffiliateService';
import CheckoutService from '@/services/resources/CheckoutService';

const serviceCoupon = CouponService.build();
const serviceProducts = ProductService.build();
const serviceAffiliate = AffiliateService.build();
const serviceCheckouts = CheckoutService.build();


export default {
  name: "CupomDesconto",
  components: {
    Paginate,
    CouponPreview,
    NewCoupon,
    Prelink,
    NovoLink
  },
  data() {
    return {
      products: [],
      productsSeller: [],
      cupom: null,
      has_cupom: false,
      product_id_afiliado: null,
      product_id: null,
      product: {
        sales_page: "",
        slug: ""
      },
      cupomMake: false,
      cupomEdit: false,
      cupomDelete: false,
      productRead: false,
      linkRead: false,
      coupons: [],
      coupon_id: -1,
      loading: false,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      client: {
        width: 0,
      },
      cupom_edit: {},
      linkCreate: false,
      checkoutRead: false,
      linkEdit: false,
      linkDelete: false,
      productsAffiliate: [],
      checkouts: [],
      cupom_name: "",
    }
  },
  mixins: [Money],
  computed: {
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    openLinks() {
      this.$router.push("/links");
    },
    editCupom(data) {
      this.cupom_edit = data;
      this.openModal("coupon-new");
    },
    // helper para abrir modais
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    getStatus(status) {
      if (status) {
        switch (status.toLowerCase()) {
          case "active": return { text: this.$t('views.cupom.text_2380'), class: this.isHeaven ? 'is-green heaven' :'is-green' }
          case "disabled": return { text: this.$t('views.cupom.text_2381'), class: "is-light-blue" }
          case "deleted": return { text: this.$t('views.cupom.text_2382'), class: "is-red" }
          case "expired": return { text: this.$t('views.cupom.text_2383'), class: "is-warning" }
          default: return { text: status, class: "is-black" }
        }
      } else return { text: status, class: "is-black" }
    },
    openPanel(coupon_id) {
      this.coupon_id = coupon_id;
      this.$router
        .push({ query: { coupon_id: this.coupon_id } })
        .catch(() => {});

      setTimeout(() => {
        this.$refs.couponPreview.openPanel(this.coupon_id);
        this.loading = false;
      });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchCoupons();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchCoupons();
    },
    fetchCoupons() {
      this.loading = true;
      this.coupons = [];

      let data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };

      serviceCoupon
        .search(data)
        .then((response) => {
          if(response.data) this.coupons = response.data
          else this.coupons = response;
          this.pagination.totalPages = response.last_page;
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false });
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    fetchSellerProducts() {
      this.product = {
        sales_page: "",
        slug: "",
      };
      this.links = [];
      this.productsAffiliate = [];

      let data = {
        list: true,
      };

      serviceProducts.search(data).then((response) => {
        this.productsSeller = [];
        this.products = response;
        this.products.forEach((element) => {
          this.productsSeller.push({
            id: element.id,
            name: element.name,
            type: element.type,
            max_boleto_installments: element.max_boleto_installments,
          });
        });
      });
    },
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (!dados.find((x) => x.name === "CouponRead")) {
            this.$router.push("/dashboard");
          }
          if (dados.find((x) => x.name === "CouponCreate")) {
            this.cupomMake = true;
          }
          if (dados.find((x) => x.name === "CouponEdit")) {
            this.cupomEdit = true;
          }
          if (dados.find((x) => x.name === "CouponDelete")) {
            this.cupomDelete = true;
          }
          if (dados.find((x) => x.name === "ProductRead")) {
            this.productRead = true;
          }
          if (dados.find((x) => x.name === "LinkRead")) {
            this.linkRead = true;
          }
          if(dados.find((x) => x.name === "LinkCreate")) {
             this.linkCreate = true;
          }
          if(dados.find((x) => x.name === "CheckoutRead")){
             this.checkoutRead = true;
          }
          if(dados.find((x) => x.name === "LinkEdit")){
            this.linkEdit = true;
          } 
          if(dados.find((x) => x.name === "LinkDelete")){
            this.linkDelete = true;
          } 
        }
      }
    },
    async setProduct(reset, produtor = false) {
      await this.fetchSellerProducts();
      if (!produtor) {
        if (this.$route.query.affiliation_id && !reset) {
          this.product_id_afiliado = {
            id: +this.$route.query.affiliation_id,
            name: this.$route.query.p_name,
          };
        } else if (reset) {
          this.product_id_afiliado = null;
        }
      } else {
        this.product_id = {
          id: +this.$route.query.p_id,
          name: this.$route.query.p_name,
          type: this.$route.query.p_type,
        };

        if (this.has_orderbump) {
          this.$bvModal.show("links-new");
          if (this.product_id.type == "SUBSCRIPTION") {
            this.$bvToast.toast(
              `${this.$t('views.cupom.text_2384')} ${this.product_id.name} ${this.$t('views.cupom.text_984')}`,
              {
                title: "Link",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        }
      }
    },
    validItem(max_boleto_installments) {
      if (max_boleto_installments !== 0 && max_boleto_installments !== null) {
        return false;
      }
      return true;
    },
    makeLink(data) {
      if (data) {
        this.setProduct(false, true);
        this.has_cupom = true;
        this.cupom = data.cupom;
        this.cupom_name = data.cupom.name;
        this.product = data.produto;
        this.$bvModal.show("links-new");
      }
    },

    fetchAffiliateProducts(l) {
      this.loading = true;

      serviceAffiliate
        .search()
        .then((response) => {          
          this.products = response.data;
          this.productsAffiliate = response.data;     
       
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchCheckouts() {
      return new Promise(async (resolve, reject) => {
        try {
          if (!this.$store.getters.setPanel || (this.$store.getters.setPanel && this.checkoutRead)) {
            const response = await serviceCheckouts.search();
            this.checkouts = response;
            resolve(response);
          } else {
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loading = true;
    this.getPermissions();
    this.fetchCoupons();

    // abrindo cupom unica
    if (typeof this.$route.query.coupon_id != "undefined") {
      this.coupon_id = this.$route.query.coupon_id;
      setTimeout(() => {
        this.$refs.couponPreview.openPanel(this.coupon_id);
        this.loading = false;
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 148, 136, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 148, 136, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 52px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2),
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}
.whats-cliente {
  font-size: 13px;
  color: #009488;
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}

// cupom
.qtd-cupom {
  font-weight: 600;
  margin: 30px 0;
}
</style>